import React from "react"

//Utils
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"

//Components
import { Image, Link } from "components/anti"

//Assets
import icFlagEnglish from "@public/img/icon/ic_flag-english.png"
import icFlagBahasa from "@public/img/icon/ic_flag-bahasa.png"
import randomString from "randomstring"
import { SET_SHOW_LOGIN } from "lib/redux/types"

const otherLinks = [
  { id: "101", title: "JOIN", link: "/register" },
  { id: "102", title: "LOGIN", link: "/login" },
  { id: "103", title: "CONFIRM PAYMENT", link: "/confirm-payment" },
  {
    id: "104",
    title: "EXIBITION/PROJECT GALLERY",
    link: "https://nagarey.wpcomstaging.com/",
  },
]

const MainDrawer = ({
  open,
  close,
  next,
  className = "",
  handleSelectMenu,
  authState,
  setAuthState,
  handleLogout,
  modalLogin,
  modalRegister,
}) => {
  //Utils ************************************************************************************************** //
  const Router = useRouter()
  const dispatch = useDispatch()
  let { categories } = useSelector((state) => state.general)
  let { pathname, query, asPath, locale, push } = Router

  //Utils ************************************************************************************************** //

  //States ************************************************************************************************* //
  const [state, setState] = React.useState({
    categories: categories,
  })
  const [lang] = React.useState(Router.locale)
  //States ************************************************************************************************* //

  //Functions ********************************************************************************************** //
  const handleNavigate = (url) => {
    if (url) Router.push(url)
    else Router.push("/")
    close()
  }

  const handleClick = (item) => {
    handleSelectMenu(item, () => next())
  }

  const handleParseLink = () => {
    let newCategories = categories
    let mainUrl = "/category"
    //Step1
    newCategories?.forEach((item) => {
      let { children, slug } = item
      if (children.length > 0) {
        //Step 2
        children?.forEach((item2) => {
          let newChildren = item2.children
          if (newChildren?.length > 0) {
            //Step 3
            newChildren.forEach((item3) => {
              item = { ...item, link: `${mainUrl}/${item3?.slug}/${item3?.id}` }
              // item?.link = `${mainUrl}/${item3?.slug}/${item3?.id}`
            })
          } else {
            item2 = { ...item2, link: `${mainUrl}/${item2?.slug}/${item2?.id}` }
            // item2?.link = `${mainUrl}/${item2?.slug}/${item2?.id}`
          }
        })
      } else {
        item = { ...item, link: `${mainUrl}/${slug}/${item?.id}` }
        // item?.link = `${mainUrl}/${slug}/${item?.id}`
      }
    })
    setState((prev) => ({ ...prev, categories: newCategories }))
  }
  //Functions ********************************************************************************************** //

  React.useEffect(() => {
    handleParseLink()
  }, [categories])

  const handleChangeLang = () => {
    Router.replace(
      {
        pathname,
        query,
      },
      asPath,
      { locale: locale === "en" ? "id" : "en" }
    )
  }

  return (
    <div
      className={`side-drawer-mobile d-lg-none ${className} ${
        open ? "open" : ""
      }`}
    >
      <div className="main-drawer-wrapper">
        <div className="with-padding">
          <div className="mobile-language-wrapper">
            <div className="flex-align-center h-100" onClick={handleChangeLang}>
              <Image
                src={Router.locale === "en" ? icFlagBahasa : icFlagEnglish}
                alt="ic_flag-english"
                objectFit="contain"
                width={20}
              />
              <div className="nav-link">
                {Router.locale.toUpperCase() === "EN" ? "ID" : "EN"}
              </div>
            </div>
            <div className="flex-align-center h-100">
              <div className="nav-item" onClick={() => close()}>
                <span className="ail ai-times nav-close"></span>
              </div>
            </div>
          </div>

          <div className="side-drawer-content">
            <ul className="nav-middle">
              <li
                className="nav-item"
                onClick={() => handleClick({ id: "new" })}
              >
                <div className={`nav-link`}>
                  {locale === "id" ? "Terbaru" : "New"}
                </div>
              </li>
              <li
                className="nav-item text-danger"
                onClick={() => handleClick({ id: "sale" })}
              >
                <div className={`nav-link`}>Sale</div>
              </li>
              {state.categories?.map((item, i) => (
                <li
                  className="nav-item"
                  key={i}
                  onClick={() => handleClick(item)}
                >
                  <div
                    className={`nav-link ${
                      item.children?.length > 0 ? "w-arrow" : ""
                    }`}
                  >
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="drawer-bottom">
          <div className="with-padding">
            {authState.isLogin ? (
              <>
                <div
                  className={`other-links with-border py-3`}
                  onClick={handleLogout}
                >
                  {Router.locale === "id" ? "KELUAR" : "LOGOUT"}
                </div>
                <div
                  className={`other-links with-border py-3`}
                  onClick={() => {
                    Router.push("/account")
                    close()
                  }}
                >
                  {Router.locale === "id" ? "AKUN SAYA" : "MY ACCOUNT"}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`other-links pt-3`}
                  onClick={() => {
                    setAuthState((prev) => ({
                      ...prev,
                      showLoginModal: modalLogin,
                    }))
                    close()
                  }}
                >
                  {Router.locale === "id" ? "MASUK" : "LOGIN"}
                </div>
                <div
                  className={`other-links with-border py-3`}
                  onClick={() => {
                    setAuthState((prev) => ({
                      ...prev,
                      showRegisterModal: modalRegister,
                    }))
                    close()
                  }}
                >
                  {Router.locale === "id" ? "DAFTAR" : "REGISTER"}
                </div>
              </>
            )}
            <div
              className={`other-links with-border py-3`}
              onClick={() => {
                Router.push("/confirm-payment")
                close()
              }}
            >
              {Router.locale === "id"
                ? "KONFIRMASI PEMBAYARAN"
                : "CONFIRM PAYMENT"}
            </div>
            <Link
              to="https://nagarey.wpcomstaging.com/"
              target="_blank"
              onClick={() => close()}
            >
              <div className={`other-links py-3`}>
                {Router.locale === "en"
                  ? "EXHIBITION / PROJECT GALLERY"
                  : "PAMERAN"}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainDrawer
